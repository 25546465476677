import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { VmConf, VmConfBody } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export const queryKey: QueryKey = ["config/network/iftune"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<VmConf> => {
            return getResponseData(await client.GET("/config/network/iftune", { signal }));
        },
    });
}

export const vmConfQueryAtom = atomFromStandardQueryOptions(getQuery);

//#region Mutations

export const updateVmConfMutation = adminMutation((apiClient) => ({
    entity: "vm_conf",
    operation: "update",
    requestFn: async ({ body }: { body: VmConfBody }) =>
        await apiClient.PUT("/config/network/iftune", {
            ...jsonEncodedBody,
            body,
        }),
    // XXX: not working - does not invalidate the query
    invalidateQueryKey: queryKey,
}));

//#endregion Mutations
